body {
    font-family: Arial, sans-serif;
    text-align: center;
    margin-top: 50px;
}

#game {
    margin: 0 auto;
    width: 300px;
}

button {
    padding: 20px;
    font-size: 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #45a049;
}
